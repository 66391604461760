// Copyright © 2025 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.starter-kit-banner
  padding: $cs.s
  justify-content: space-between
  border-radius: $br.xl
  border: 1px solid var(--c-bg-info-semilight)
  background: var(--c-bg-info-extralight)
  gap: $cs.l

  +media-query($bp.md-lg)
    border: none
    border-radius: 0
    align-items: center
    gap: $cs.l
    padding: $cs.m
    z-index: $zi.nav

  &-title
    line-height: 1
    margin: 0 0 $cs.xs

    +media-query($bp.md-lg)
      line-height: normal
      color: var(--c-text-info-bold)
      font-size: $fs.m

  &-image
    width: 13rem

    +media-query($bp.md-lg)
      width: 4rem
