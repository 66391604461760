// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.status
  position: relative
  display: inline-block
  width: 5px
  height: 5px
  border-radius: 50%
  transition: background-color $ad.m
  vertical-align: middle

  &-big
    width: 8px
    height: 8px

  &:not(.dot-only)
    &:not(.flipped)
      margin-left: $cs.xs
    &.flipped
      margin-right: $cs.xs

  &:after
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    border-radius: 50%
    width: 5px
    height: 5px
    opacity: .7
    animation: pulse 2s infinite

  &-green
    background-color: var(--c-bg-success-normal)

  &-good
    background-color: var(--c-text-brand-normal)

  &-bad
    background-color: var(--c-bg-error-normal)

  &-mediocre
    background-color: var(--c-bg-warning-normal)

  &-unknown
    background-color: var(--c-icon-neutral-normal)

.good-pulse:after
  animation: goodPulse 4s infinite

.triggered-good-pulse:after
  animation: triggeredGoodPulse 1.5s ease-out

.green-pulse:after
  animation: greenPulse 4s infinite

.triggered-green-pulse:after
  animation: triggeredGreenPulse 1.5s ease-out

pulse-animation($color, $name)
  @keyframes $name
    0%
      box-shadow: 0 0 0 0 alpha($color, .4)

    50%
      box-shadow: 0 0 0 6px alpha($color, 0)

    70%
      box-shadow: 0 0 0 0 alpha($color, 0)

    100%
      box-shadow: 0 0 0 0 alpha($color, 0)

triggered-pulse-animation($color, $name)
  @keyframes $name
    0%
      box-shadow: 0 0 0 0 alpha($color, 1)

    70%
      box-shadow: 0 0 4px 10px alpha($color, 0)

    100%
      box-shadow: 0 0 0 0 alpha($color, 0)

pulse-animation($tokens.theme-light.color.icon-info-normal, 'goodPulse')
pulse-animation($tokens.theme-light.color.icon-success-normal, 'greenPulse')

triggered-pulse-animation($tokens.theme-light.color.icon-info-normal, 'triggeredGoodPulse')
triggered-pulse-animation($tokens.theme-light.color.icon-success-normal, 'triggeredGreenPulse')
