// Copyright © 2025 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  background-color: var(--c-bg-neutral-heavy)
  color: var(--c-text-neutral-min)
  padding: $cs.m $cs.l $cs.l $cs.l
  border-radius: $br.xxl
  margin: 0 0 $cs.s 0
  width: 21rem
  box-shadow: var(--shadow-box-modal-normal)

.title
  margin: 0
  font-size: $fs.m

.subtitle
  font-size: $fs.s
  margin: $cs.xs 0 $cs.m 0

.image
  width: 100%

.buttonGroup
  display: flex
  justify-content: space-between
  gap: $cs.m
  margin-top: $cs.m

  .button
    flex: 1

.closeButton
  position: absolute
  top: $cs.m
  right: $cs.m
