// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.shortcut
  padding: $cs.s $cs.m
  color: var(--c-text-brand-normal)
  background-color: var(--c-bg-brand-light)
  border-radius: $br.l
  height: 4.5rem
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  gap: $cs.xs
  font-weight: $fw.bold
  border: 0

  +media-query(1304px)
    padding: $cs.xxs $cs.xs

  .icon
    color: var(--c-icon-brand-normal)

  &:hover
    cursor: pointer
    background-color: var(--c-bg-brand-semilight)

  &-panel
    height: unset
    aspect-ratio: 1 / 1
    box-sizing: border-box
