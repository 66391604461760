// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.item
  display: flex
  gap: $cs.m
  transition: background-color $ad.s ease-in-out
  border-radius: $br.xl
  padding: $cs.xs
  height: 6rem
  box-sizing: border-box

  &:hover
    background-color: var(--c-bg-brand-extralight)

  .image-container
    height: 100%
    flex-shrink: 0

    .image
      border-radius: $br.l
      border: 1px solid var(--c-border-neutral-light)
      object-fit: cover
      height: 100%
      aspect-ratio: 16 / 9

  .title
    width: 100%
    font-weight: $fw.bolder
    color: var(--c-text-neutral-heavy)
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2 /* start showing ellipsis when 2nd line is reached */
    white-space: pre-wrap
    line-height: 1.15
