// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.device-image
  width: 9.5rem
  height: 9.5rem
  min-width: 6.5rem
  min-height: 6.5rem
  border-radius: $br.m
  border: 2px solid var(--c-border-neutral-light)
  overflow: hidden
  display: flex
  justify-content: center

  &-placeholder
    width: 6rem
  img
    object-fit: cover
    width: 100%
    height: 100%

@container panel (max-width: 485px)
  .button-group
    flex-direction: column
    justify-content: flex-start
    align-items: stretch

  .button:not(:last-child)
    // stylelint-disable-next-line declaration-no-important
    margin-bottom: $cs.s !important
    // stylelint-disable-next-line declaration-no-important
    margin-right: 0 !important
